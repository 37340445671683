import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useStore} from 'react-hookstore';
import {colors, media, radius} from '../modules/styles';
import {Icon} from '@blueprintjs/core';
import {getNameByCode} from '../modules/jurisdictions';
import ENV from '../environment';
import useAddToReport from '../hooks/useAddToReport';
import AddToReportButton from './AddToReportButton';

export default function PolicyContents({
  provisions,
  showAreaName = true,
  isRemoveEnabled = false,
  isReportIssueEnabled = true,
  isPermalinkEnabled = true,
  onRemoveSection,
}) {
  const [updatedProvs, setUpdatedProvs] = useState(null);
  const [currentUser] = useStore('currentUser');
  const {createReport, appendToReport} = useAddToReport();

  let lastArea;
  let lastProvisionGroup;

  useEffect(() => {
    // Save provs in state so the provs shown in the UI will update when one is added or deleted
    provisions && setUpdatedProvs(provisions);
  }, [provisions]);

  return (
    <Comp>
      {updatedProvs
        ?.map(prov => {
          const output = [];

          // We need to show the various AoL names in the saved reports
          if (showAreaName && prov.area.id !== lastArea) {
            lastArea = prov.area.id;
            output.push(
              <Area key={prov.area.id} className="area-header">
                {prov.area.name}
              </Area>
            );
          }

          // If the provision is not published then don't show it unless this is a staff user
          if (!prov.isPublished && !currentUser.isStaff) {
            return null;
          }

          const reportIssueBody = encodeURIComponent(
            `Hi,\n\nI'd like to report an issue with the following Provision:\n\nArea of Law: ${prov.area?.name}\nTopic: ${prov.topic?.name}\nProvision: ${prov.name}\nJurisdiction: ${prov.jurisdiction}\n`
          );

          const jurBlock = (
            <Jurisdiction
              key={prov.jurisdiction}
              className={`${prov.isPublished ? '' : 'not-published'}`}
            >
              <JursLeft>
                <JurisdictionName>
                  <Icon icon="map" />
                  <JurDateWrapper>
                    {getNameByCode(prov.jurisdiction)}

                    <LastUpdated>
                      Updated:{' '}
                      {moment(prov.updatedAt || prov.updatedAt).format(
                        'MMM DD, YYYY'
                      )}
                    </LastUpdated>
                  </JurDateWrapper>
                </JurisdictionName>

                <AddToReportButton
                  onCreate={name => createReport(name, [prov.id])}
                  onAppend={reportId => appendToReport(reportId, [prov.id])}
                >
                  <AddToReportLink onClick={e => e.preventDefault()}>
                    <Icon icon="add" iconSize={12} /> Add To Report
                  </AddToReportLink>
                </AddToReportButton>

                <AddToReportLink
                  className="copy"
                  onClick={() => navigator.clipboard.writeText(prov.textPolicy)}
                >
                  <Icon icon="duplicate" iconSize={12} /> Copy
                </AddToReportLink>

                <AddToReportLink
                  className="view-provision"
                  onClick={() =>
                    window.open(
                      `${ENV.CLIENT_HOST}/m/builder/${prov.area.id}/${prov.topic.id}#${prov._id}`,
                      '_blank'
                    )
                  }
                >
                  <Icon icon="search-template" iconSize={12} /> View Provision
                </AddToReportLink>

                {isReportIssueEnabled && (
                  <ReportAnIssue
                    className="report-an-issue"
                    href={`mailto:info@complianceworks.ca?subject=Issue with Provision&body=${reportIssueBody}`}
                  >
                    <Icon icon="issue" iconSize={12} /> Report an Issue
                  </ReportAnIssue>
                )}

                {prov.area?.id && prov.topic?.id && isPermalinkEnabled && (
                  <ProvPermalink
                    className="prov-permalink"
                    href={`${ENV.CLIENT_HOST}/m/policies/${prov.area.id}/${prov.topic.id}#${prov._id}`}
                  >
                    <Icon icon="link" iconSize={12} /> Permalink
                  </ProvPermalink>
                )}

                {isRemoveEnabled && (
                  <Delete
                    className="delete"
                    onClick={() => onRemoveSection(prov._id)}
                  >
                    <Icon icon="trash" iconSize={10} /> Remove this Section
                  </Delete>
                )}
              </JursLeft>
              <JursRight>
                <Cols>
                  <div style={{width: '100%'}}>
                    <Description
                      dangerouslySetInnerHTML={{
                        __html: prov.policy || '<i>Details coming soon.</i>',
                      }}
                    />
                  </div>
                </Cols>
              </JursRight>
            </Jurisdiction>
          );

          let provHeader = null;
          if (prov.provisionGroup !== lastProvisionGroup) {
            lastProvisionGroup = prov.provisionGroup;
            provHeader = (
              <Name
                key={`provHeader-${prov.provisionGroup}`}
                id={prov.provisionGroup}
                name={prov.provisionGroup}
                className="name-header"
              >
                <TopicName>
                  {prov.area?.name}: {prov.topic?.name}
                </TopicName>
                <ProvisionName>{prov.name}</ProvisionName>
              </Name>
            );
          }

          output.push(
            provHeader,
            <a
              className="anchor"
              href="/" // Needed for a11y
              key={`anchor-${prov._id}`}
              id={prov._id}
            >
              {prov.name} {/* a value is needed for a11y */}
            </a>,
            <Provision key={prov._id}>{jurBlock}</Provision>
          );

          return output;
        })
        .filter(Boolean)}
    </Comp>
  );
}

const Comp = styled.div`
  /* https://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header */
  a.anchor {
    display: block;
    position: relative;
    top: -40px;
    font-size: 0px;
    line-height: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    visibility: hidden;
  }
`;

const Area = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: ${colors.secondary};

  .bp3-icon {
    color: ${colors.primary};
    margin-right: 15px;
  }
`;

const Provision = styled.div`
  font-size: 1rem;

  .bp3-icon {
    color: ${colors.primary};
    margin-right: 5px;
  }

  /* This adds space above a name-header after a provision, giving space between main sections */
  & + .area-header {
    margin-top: 50px;
  }

  /* This adds space above a name-header after a provision, giving space between main sections */
  & + .name-header {
    margin-top: 30px;
  }
`;

const Jurisdiction = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  &.not-published {
    opacity: 0.5;
  }

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    margin: 30px 0 10px 0;

    &:hover .delete,
    &:hover .report-an-issue,
    &:hover .prov-permalink {
      visibility: visible;
    }

    // &:hover .add-note {
    //   visibility: visible;
    //   cursor: pointer;
    // }
  }
`;

const JurisdictionName = styled.div`
  display: flex;
  .bp3-icon {
    color: ${colors.primary};
    margin-right: 5px;
  }
`;

const JurDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 5px 0;

  @media (min-width: ${media.lg}) {
    flex-direction: column;
    margin: 0;
  }
`;

const JursLeft = styled.div`
  @media (min-width: ${media.lg}) {
    min-width: 180px;
    max-width: 180px;
    
  .bp3-popover2-target {
    width: 100%;
  }
`;

const JursRight = styled.div`
  width: 100%;
`;

const Name = styled.div`
  position: sticky;
  top: 59px;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: white;
  padding: 5px 2px; // The 2px on the sides helps because a "J" bleeds out the left side a little when the header is sticky
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${colors.gray2};
`;

const ProvisionName = styled.div`
  display: flex;
  align-items: center;
`;

const TopicName = styled.div`
  font-size: 0.8rem;
`;

const LastUpdated = styled.div`
  font-size: 0.8rem;

  @media (min-width: ${media.lg}) {
    font-size: 0.8rem;
  }
`;

const Delete = styled.button`
  visibility: hidden;
  display: none;
  border: none;
  border-radius: ${radius};
  color: ${colors.danger};
  font-size: 0.8rem;
  margin: 10px 0 0 0;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.dangerLight};
  }

  .bp3-icon {
    color: ${colors.danger};
    margin-right: 8px;
  }

  @media (min-width: ${media.lg}) {
    display: flex;
  }
`;

const CompareVersion = styled.button`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
    border: none;
    border-radius: ${radius};
    color: ${colors.yellowCallout};
    font-size: 0.8rem;
    margin: 10px 40px 0 0;
    padding: 4px 8px;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: ${colors.yellowCalloutLight};
      color: ${colors.yellowCallout};
    }

    .bp3-icon {
      color: ${colors.yellowCallout};
      margin-right: 8px;
    }
  }
`;

const AddToReportLink = styled(CompareVersion)`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
    justify-self: stretch;
`;

const ReportAnIssue = styled.a`
  visibility: hidden;
  display: none;
  border: none;
  border-radius: ${radius};
  color: ${colors.yellowCallout};
  font-size: 0.8rem;
  margin: 10px 40px 0 0;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${colors.yellowCalloutLight};
    color: ${colors.yellowCallout};
    text-decoration: none;
  }

  .bp3-icon {
    color: ${colors.yellowCallout};
    margin-right: 8px;
  }

  @media (min-width: ${media.lg}) {
    display: flex;
  }
`;

const ProvPermalink = styled.a`
  visibility: hidden;
  display: none;
  border: none;
  border-radius: ${radius};
  color: ${colors.yellowCallout};
  font-size: 0.8rem;
  margin: 10px 40px 0 0;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${colors.yellowCalloutLight};
    color: ${colors.yellowCallout};
    text-decoration: none;
  }

  .bp3-icon {
    color: ${colors.yellowCallout};
    margin-right: 8px;
  }

  @media (min-width: ${media.lg}) {
    display: flex;
  }
`;

const Description = styled.div`
  // margin: 20px 0;

  a {
    color: ${colors.primary};
    text-decoration: underline;
    font-weight: 600;
  }

  mark {
    background-color: ${colors.searchHighlight};
  }

  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-indent-1 {
    padding-left: 3em;
  }
  .ql-indent-2 {
    padding-left: 6em;
  }
  .ql-indent-3 {
    padding-left: 9em;
  }
  .ql-indent-4 {
    padding-left: 12em;
  }
  .ql-indent-5 {
    padding-left: 15em;
  }
  .ql-indent-6 {
    padding-left: 18em;
  }
  .ql-indent-7 {
    padding-left: 21em;
  }
  .ql-indent-8 {
    padding-left: 24em;
  }
  .ql-indent-9 {
    padding-left: 27em;
  }
`;

const Cols = styled.div`
  display: flex;
  flex-direction: column;
`;
