import React from 'react';
import styled from 'styled-components';
import differenceBy from 'lodash.differenceby';
import {MultiSelect} from '@blueprintjs/select';
import {MenuItem} from '@blueprintjs/core';

export default function SuggestMultiSelect({
  id,
  items,
  disabled = false,
  selectedItems,
  onSelect,
  onRemove,
  children,
  position = 'bottom',
  filterable = false,
  enableNew = true,
  placeholder = 'Search or create item...',
  hideSelectedItems = true,
  tagInputProps,
  ...props
}) {
  const unselectedItems = differenceBy(items, selectedItems, 'id');

  return (
    <Comp>
      <MultiSelect
        id={id}
        items={disabled ? [] : hideSelectedItems ? unselectedItems : items}
        selectedItems={selectedItems}
        onItemSelect={onSelect}
        resetOnClose={true}
        filterable={filterable}
        noResults={<MenuItem disabled={true} text="No results." />}
        popoverProps={{
          rootBoundary: 'viewport',
          boundary: 'viewport',
          position: position,
          portalClassName: 'filter-select-popover',
        }}
        itemPredicate={(query, item) => {
          const qLower = query.toLowerCase();
          const nLower = item.name.toLowerCase();

          return nLower.startsWith(qLower);
        }}
        itemRenderer={(item, {handleClick, modifiers}) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }
          const isSelected = !!selectedItems.find(i => i.id === item.id);
          return (
            <MenuItem
              active={modifiers.active}
              key={item.id || item.name}
              icon={isSelected ? 'tick' : 'blank'}
              text={item.name}
              intent={isSelected ? 'primary' : 'none'}
              onClick={handleClick}
            />
          );
        }}
        tagRenderer={item => (item ? item.name : '')}
        tagInputProps={{
          tagProps: {intent: 'primary', minimal: true},
          onRemove: onRemove && !disabled ? onRemove : () => {},
          placeholder: placeholder,
          disabled: disabled,
          ...tagInputProps,
        }}
        createNewItemRenderer={(query, active, handleClick) => {
          if (disabled || !enableNew) {
            return null;
          }
          return (
            <MenuItem
              icon="add"
              text={`Create "${query}"`}
              active={active}
              onClick={handleClick}
              shouldDismissPopover={false}
            />
          );
        }}
        createNewItemFromQuery={query => {
          if (query) {
            return {id: query, name: query};
          }
        }}
        {...props}
      >
        {children}
      </MultiSelect>
    </Comp>
  );
}

const Comp = styled.div`
  input {
    min-width: 200px;
  }
`;
