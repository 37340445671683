import React, {useState, useEffect, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {useStore} from 'react-hookstore';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import NoData from '../assets/no_data.svg';
import {colors, media, radius} from '../modules/styles';
import useAddToReport from '../hooks/useAddToReport';
import PolicyContents from '../components/PolicyContents';
import PoliciesFilters from '../components/PoliciesFilters';

export default function PoliciesPg() {
  const [isLoading, setIsLoading] = useState(true);
  const [areas, setAreas] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);

  const [currentUser] = useStore('currentUser');
  const {createReport, appendToReport} = useAddToReport();

  const filteredData = useMemo(
    () =>
      reportData.filter(
        prov =>
          (selectedAreas.length === 0 ||
            selectedAreas.includes(prov.area.id)) &&
          (selectedTopics.length === 0 ||
            selectedTopics.includes(prov.topic.name)) &&
          (selectedJurisdictions.length === 0 ||
            selectedJurisdictions.includes(prov.jurisdiction))
      ),
    [reportData, selectedAreas, selectedTopics, selectedJurisdictions]
  );

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await fetcher.get('/api/buildReportViaPolicies');
      setReportData(res.data);
      setIsLoading(false);
    } catch (e) {
      notifications.error(`Error building report: ${e.message}`);
      setReportData([]);
    }
  }, []);

  // First time load... Populate the pulldowns
  useEffect(() => {
    const loadOptionsData = async () => {
      try {
        const areasRes = await fetcher.get('/api/areas/withPrunedTopics');
        setAreas(areasRes.data);
        await loadData([]);
      } catch (e) {
        notifications.error(`Error loading menu data: ${e.message}`);
      }
    };

    loadOptionsData();
  }, [loadData]);

  const handleCreateReport = async name => {
    createReport(
      name,
      filteredData.map(p => p.id)
    );
  };

  const handleAppendToReport = async reportId => {
    appendToReport(
      reportId,
      filteredData.map(p => p.id)
    );
  };

  return (
    <Comp>
      <Helmet title="Policies" />

      <PoliciesFilters
        currentUser={currentUser}
        areas={areas}
        selectedAreas={selectedAreas}
        selectedTopics={selectedTopics}
        selectedJurisdictions={selectedJurisdictions}
        setSelectedAreas={setSelectedAreas}
        setSelectedTopics={setSelectedTopics}
        setSelectedJurisdictions={setSelectedJurisdictions}
        onCreateReport={handleCreateReport}
        onAppendToReport={handleAppendToReport}
        results={reportData}
      />

      <Content>
        <h1>Policies</h1>

        {!isLoading && filteredData.length > 0 && (
          <Box>
            <PolicyContents
              provisions={filteredData}
              showAreaName={false}
              isRemoveEnabled={false}
              onCreateReport={createReport}
              onAppendToReport={appendToReport}
            />
          </Box>
        )}

        {filteredData.length === 0 && !isLoading && (
          <NoReports>
            <img src={NoData} alt="No results found" />

            <h2>No results found.</h2>
            <p>Please adjust your filters and try again.</p>
          </NoReports>
        )}
      </Content>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    padding: 0px;
  }
`;

const Content = styled.section`
  margin: 20px 0 0 0;
  width: 100%;

  @media (min-width: ${media.lg}) {
    margin: 20px 46px 0 330px;
  }

  @media (min-width: ${media.xl}) {
    margin: 20px 250px 0 330px;
  }
`;

const Box = styled.div`
  border: 1px solid ${colors.gray2};
  background-color: white;
  border-radius: ${radius};
  padding: 5px 10px;
  width: 100%;
  max-width: 1100px;
  margin-bottom: 30px;

  @media (min-width: ${media.lg}) {
    padding: 15px 30px;
    position: relative;
  }
`;

const NoReports = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;

  img {
    width: 40%;
  }

  h2 {
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }

  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
`;
