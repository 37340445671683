import './normalize.css';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import 'instantsearch.css/themes/satellite.css';

import './base.css';

import React, {useEffect, useCallback, useState, Suspense, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {InstantSearch} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';
import {useStore} from 'react-hookstore';
import useStoreSetup from '../hooks/useStoreSetup';

import AuthRequired from '../components/AuthRequired';
import GATracker from '../components/GATracker';
import HubspotTracker from '../components/HubspotTracker';
import ScrollToTop from '../components/ScrollToTop';
import Spinner from '../components/Spinner';

import fetcher from '../modules/fetcher';
import {emit} from '../modules/eventManager';
import Header from '../components/Header';
import AdminHeader from '../components/AdminHeader';
import ENV from '../environment';
import DisclaimerPopup from '../components/DisclaimerPopup';
import FreeTrialHeader from '../components/FreeTrialHeader';
import AccountLockedHeader from '../components/AccountLockedHeader';
import {isOnFreeTrial} from '../modules/subscriptions';

/**
 * * Due to a breaking change in React, we have to use the "render" prop instead of
 * * the "component" prop on routes that use lazy().
 * * https://github.com/ReactTraining/react-router/issues/6056#issuecomment-404512441
 */

// Preload the most frequent pages. Also, preloading BuilderPg is *required* for the header
// search to work properly otherwise the first few characters glitch as the page tries to load.
import UpdatesPg from '../pages/UpdatesPg';
import BuilderPg from '../pages/BuilderPg';
import SearchPg from '../pages/SearchPg';
import ChecklistsPg from '../pages/ChecklistsPg';
import SignupPg from '../pages/SignupPg';
import PoliciesPg from '../pages/PoliciesPg';
import {media} from '../modules/styles';
import useEvent from '../hooks/useEvent';

const TrackerPg = React.lazy(() => import('../pages/TrackerPg'));
const MyInfoPg = React.lazy(() => import('../pages/MyInfoPg'));

// Public Pages
const LoginPg = React.lazy(() => import('../pages/LoginPg'));
// const SignupPg = React.lazy(() => import('../pages/SignupPg'));
const ForgotPasswordPg = React.lazy(() => import('../pages/ForgotPasswordPg'));
const ResetPasswordPg = React.lazy(() => import('../pages/ResetPasswordPg'));
const PrivacyPg = React.lazy(() => import('../pages/PrivacyPg'));
const TermsPg = React.lazy(() => import('../pages/TermsPg'));
const ScopeOfProductPg = React.lazy(() => import('../pages/ScopeOfProductPg'));
const ProvisionPreviewPg = React.lazy(() =>
  import('../pages/ProvisionPreviewPg')
);
const ProvisionManagerPg = React.lazy(() =>
  import('../pages/ProvisionManagerPg')
);
const DashboardPg = React.lazy(() => import('../pages/DashboardPg'));
const BillTracker2Pg = React.lazy(() => import('../pages/BillTracker2Pg'));
const IndustriesPg = React.lazy(() => import('../pages/IndustriesPg'));
const ActsRegsPg = React.lazy(() => import('../pages/ActsRegsPg'));
const ChecklistsManagerPg = React.lazy(() =>
  import('../pages/ChecklistsManagerPg')
);
const AccountsPg = React.lazy(() => import('../pages/AccountsPg'));
const SettingsPg = React.lazy(() => import('../pages/SettingsPg'));
const PreferencesPg = React.lazy(() => import('../pages/PreferencesPg'));
const NotificationsPg = React.lazy(() => import('../pages/NotificationsPg'));
const ActivateSubscriptionPg = React.lazy(() =>
  import('../pages/ActivateSubscriptionPg')
);
const ActivateSubscriptionSuccessPg = React.lazy(() =>
  import('../pages/ActivateSubscriptionSuccessPg')
);

let searchClient;

export default function App() {
  const appRef = useRef();

  // initialize the store state
  useStoreSetup();
  const [currentUser] = useStore('currentUser');
  const [siteWidth, setSiteWidth] = useStore('siteWidth');
  const [, setIsMobile] = useStore('isMobile');
  const [currentUserCheckComplete, setCurrentUserCheckComplete] =
    useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);

  useEffect(() => {
    if (appRef.current?.clientWidth !== siteWidth) {
      const width = appRef.current?.clientWidth || 0;
      const breakpoint = Number(media.lg.replace('px', ''));

      setSiteWidth(width);
      setIsMobile(width <= breakpoint);
    }
  });

  /* Customised Route Components */
  const RedirectHome = () => {
    if (currentUser && currentUser.isStaff) {
      return <Redirect to={ENV.ADMIN_HOME_PAGE} />;
    } else if (currentUser && !currentUser.isStaff) {
      return <Redirect to={ENV.HOME_PAGE} />;
    } else {
      return <Redirect to="/login" />;
    }
  };

  const loadCurrentUser = useCallback(async () => {
    try {
      const res = await fetcher.get('/currentUser');
      if (res && res.data) {
        emit('user:loggedIn', res.data);
      }
      setCurrentUserCheckComplete(true);
    } catch (e) {
      console.error('Lookup of current user failed:', e.toString());
    }
  }, []);

  // Get the current user
  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  useEvent('user:trialExpired', () => {
    setIsTrialExpired(true);
  });
  useEvent('user:clearExpiredTrial', () => {
    // This means the user just subscribed and we need to let them in
    setIsTrialExpired(false);
  });

  useEvent('user:loggedIn', event => {
    searchClient = algoliasearch(ENV.ALGOLIA_APP_ID, event.data.searchToken);
  });

  if (!currentUserCheckComplete) {
    return null;
  }

  return (
    <BrowserRouter>
      <Comp ref={appRef}>
        <GATracker id="UA-155202310-1" />
        <HubspotTracker />
        <ScrollToTop speed={800} />
        <Helmet titleTemplate="%s | Compliance Works">
          <title>Welcome to Compliance Works</title>
        </Helmet>

        <Suspense
          fallback={
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          }
        >
          <Switch>
            <Route
              exact={true}
              path="/health"
              render={() => <div>{new Date().toISOString()}</div>}
            />
            <Route exact={true} path="/" component={RedirectHome} />
            <Route
              exact={true}
              path="/login"
              render={props => <LoginPg {...props} />}
            />
            <Route
              exact
              path="/signup"
              // render={() => <Redirect to="/signup/plans" />}
              render={() =>
                window.location.replace('https://complianceworks.ca/')
              }
            />
            <Route
              exact
              path="/signup/plans"
              render={() =>
                window.location.replace('https://complianceworks.ca/')
              }
            />

            <Route path="/signup" render={props => <SignupPg {...props} />} />

            <Route
              exact={true}
              path="/forgotPassword"
              render={props => (
                <ForgotPasswordPg {...props} currentUser={currentUser} />
              )}
            />
            <Route
              exact={true}
              path="/resetPassword/:email/:token"
              render={props => <ResetPasswordPg {...props} />}
            />
            <Route
              exact={true}
              path="/privacy"
              render={props => <PrivacyPg {...props} />}
            />
            <Route
              exact={true}
              path="/scopeOfProduct"
              render={props => <ScopeOfProductPg {...props} />}
            />
            <Route
              exact={true}
              path="/terms"
              render={props => <TermsPg {...props} />}
            />
            <Route
              exact={true}
              path="/preview/:id"
              render={props => <ProvisionPreviewPg {...props} />}
            />

            {currentUser && isTrialExpired && (
              <Route
                path="/m/"
                render={props => (
                  <AuthRequired>
                    <AuthLayout>
                      <Suspense
                        fallback={
                          <SpinnerWrapper>
                            <Spinner />
                          </SpinnerWrapper>
                        }
                      >
                        <AccountLockedHeader />

                        <Switch>
                          <Route
                            exact
                            path="/m/activateSubscription/success"
                            render={props => (
                              <ActivateSubscriptionSuccessPg {...props} />
                            )}
                          />

                          <Route
                            render={() => (
                              <>
                                <LockedWrapper>
                                  <ActivateSubscriptionPg {...props} />
                                </LockedWrapper>
                              </>
                            )}
                          />
                        </Switch>
                      </Suspense>
                    </AuthLayout>
                  </AuthRequired>
                )}
              />
            )}

            {currentUser && !isTrialExpired && (
              <Route
                path="/m/"
                render={props => (
                  <AuthRequired>
                    <AuthLayout>
                      <Suspense
                        fallback={
                          <SpinnerWrapper>
                            <Spinner />
                          </SpinnerWrapper>
                        }
                      >
                        <InstantSearch
                          indexName="main"
                          searchClient={searchClient}
                        >
                          <Header {...props} />

                          {currentUser.account.id !== ENV.OC_ACCOUNT_ID && (
                            <FreeTrialHeader />
                          )}

                          <DisclaimerPopup />

                          <Main
                            className={
                              isOnFreeTrial(currentUser.account) ? 'trial' : ''
                            }
                          >
                            <Switch>
                              <Route
                                exact
                                path="/m/updates"
                                component={UpdatesPg}
                              />

                              <Route
                                exact
                                path="/m/builder"
                                component={BuilderPg}
                              />
                              <Route
                                exact
                                path="/m/builder/:areaId/:topicId"
                                component={BuilderPg}
                              />

                              {currentUser && currentUser.isStaff && (
                                <Route
                                  exact
                                  path="/m/policies"
                                  component={PoliciesPg}
                                />
                              )}
                              {currentUser && currentUser.isStaff && (
                                <Route
                                  exact
                                  path="/m/policies/:areaId/:topicId"
                                  component={PoliciesPg}
                                />
                              )}

                              <Route
                                exact
                                path="/m/newSearch"
                                component={SearchPg}
                              />

                              <Route
                                exact
                                path="/m/search"
                                component={BuilderPg}
                              />

                              <Route
                                exact
                                path="/m/checklists"
                                component={ChecklistsPg}
                              />

                              <Route
                                exact
                                path="/m/tracker"
                                component={TrackerPg}
                              />
                              <Route path="/m/my" component={MyInfoPg} />

                              <Route
                                exact
                                path="/m/preferences"
                                component={PreferencesPg}
                              />
                              <Route
                                exact={true}
                                path="/m/privacy"
                                render={props => <PrivacyPg {...props} />}
                              />
                              <Route
                                exact={true}
                                path="/m/terms"
                                render={props => <TermsPg {...props} />}
                              />
                              <Route
                                exact={true}
                                path="/m/scopeOfProduct"
                                render={props => (
                                  <ScopeOfProductPg {...props} />
                                )}
                              />

                              {currentUser.role === 'ACCOUNT_OWNER' && (
                                <Route
                                  exact
                                  path="/m/activateSubscription"
                                  render={props => (
                                    <ActivateSubscriptionPg {...props} />
                                  )}
                                />
                              )}
                              {currentUser.role === 'ACCOUNT_OWNER' && (
                                <Route
                                  exact
                                  path="/m/activateSubscription/success"
                                  render={props => (
                                    <ActivateSubscriptionSuccessPg {...props} />
                                  )}
                                />
                              )}

                              {/* Even though these next two routes have the same perms check, don't wrap them together in a fragment tag as the Router doesn't like that */}
                              {(currentUser.role === 'ACCOUNT_OWNER' ||
                                currentUser.role === 'ADMIN') && (
                                <Route
                                  exact
                                  path="/m/settings"
                                  render={() => (
                                    <Redirect to="/m/settings/account" />
                                  )}
                                />
                              )}
                              {(currentUser.role === 'ACCOUNT_OWNER' ||
                                currentUser.role === 'ADMIN') && (
                                <Route
                                  path="/m/settings"
                                  component={SettingsPg}
                                />
                              )}
                            </Switch>
                          </Main>
                        </InstantSearch>
                      </Suspense>
                    </AuthLayout>
                  </AuthRequired>
                )}
              />
            )}

            {currentUser && currentUser.isStaff && (
              <Route
                path="/a/"
                render={props => (
                  <AuthRequired>
                    <AuthLayout>
                      <Suspense
                        fallback={
                          <SpinnerWrapper>
                            <Spinner />
                          </SpinnerWrapper>
                        }
                      >
                        <AdminHeader {...props} />
                        <Main>
                          <Switch>
                            <Route
                              exact
                              path="/a/data"
                              component={ProvisionManagerPg}
                            />
                            <Route
                              path="/a/data/:area/:topic/:provision?"
                              component={ProvisionManagerPg}
                            />
                            <Route
                              path="/a/billTracker"
                              component={BillTracker2Pg}
                            />
                            <Route
                              path="/a/dashboard"
                              component={DashboardPg}
                            />
                            <Route
                              path="/a/industries"
                              component={IndustriesPg}
                            />
                            <Route
                              exact
                              path="/a/acts"
                              component={ActsRegsPg}
                            />
                            <Route path="/a/acts/:id" component={ActsRegsPg} />
                            <Route
                              path="/a/checklistsManager"
                              component={ChecklistsManagerPg}
                            />
                            <Route path="/a/accounts" component={AccountsPg} />

                            <Route
                              path="/a/notifications"
                              component={NotificationsPg}
                            />
                          </Switch>
                        </Main>
                      </Suspense>
                    </AuthLayout>
                  </AuthRequired>
                )}
              />
            )}

            <Route component={RedirectHome} />
          </Switch>
        </Suspense>
      </Comp>
    </BrowserRouter>
  );
}

const Comp = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`;

const AuthLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const Main = styled.main`
  margin-top: 60px; /* header height */
  width: 100%;

  &.trial {
    margin-top: 0;
  }
`;

const LockedWrapper = styled.main`
  padding: 0 30px 30px 30px;
`;
