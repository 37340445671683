import React, {useState} from 'react';
import {Dialog, Button} from '@blueprintjs/core';
import styled from 'styled-components';

export default function PolicyLanguageDialog({provision, onClose}) {
  const [copied, setCopied] = useState(false);

  const onCopy = copyValue => {
    setCopied(true);
    navigator.clipboard.writeText(copyValue);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <Comp>
      <Dialog
        isOpen={!!provision}
        title={provision?.name}
        icon="document"
        onClose={onClose}
      >
        <Main className="bp3-dialog-body">
          <div dangerouslySetInnerHTML={{__html: provision?.policy}} />
        </Main>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button
              intent="none"
              icon={copied ? 'tick' : 'duplicate'}
              onClick={() => onCopy(provision?.textPolicy)}
            >
              Copy
            </Button>
            <Button intent="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </Comp>
  );
}

const Comp = styled.div``;

const Main = styled.div`
  min-width: 700px;
  min-height: 100px;
`;
